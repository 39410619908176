import {
  BooleanField,
  DateField,
  Link,
  TextField,
} from "react-admin";
import ReactJson from "react-json-view";
import { Hash } from "../../components/Hash";

const CompetitorStatusDetail = (props) => {
  const { record } = props;

  const isBot = (id) => id ? id.toLowerCase().includes("bot") : false;

  return (
    <>
      <section className="wrapper">
        <div>
          <h1>User</h1>
          <Link to={`/User/${record?.user?.id}/show`} >{record?.user?.id}
            {isBot(record?.user?.id) && <span> (BOT)</span>}
          </Link>
        </div>
        <div>
          <h1>Preset</h1>
          {
            record?.preset?.id ?
              <Link to={`/CompetitionPreset/${record?.preset.id}/show`} >{record?.preset?.id}</Link> :
              <span> </span>
          }
        </div>
        <div>
          <h1>Competition</h1>
          {
            record?.competition?.id ?
              <Link to={`/Competition/${record?.competition.id}/show`} >{record?.competition?.id}</Link> :
              <span> </span>
          }
        </div>
        <div>
          <h1>Last API call log</h1>
          {
            record?.apiCallLog?.id ?
              <Link to={`/APICallLog/${record?.apiCallLog.id}/show`} >{record?.apiCallLog?.id}</Link> :
              <span> </span>
          }
        </div>
      </section>
      <section className="wrapper wrapper-pre">
        <div>
          <h1>Created at</h1>
          <DateField source="createdAt" showTime={true} />
        </div>
        <div>
          <h1>Joined competition at</h1>
          <DateField source='joinedCompetitionAt' showTime={true} />
        </div>
        <div>
          <h1>Match started at</h1>
          <DateField source='matchStartedAt' showTime={true} />
        </div>
        <div>
          <h1>Match ended at</h1>
          <DateField source='matchEndedAt' showTime={true} />
        </div>
      </section>
      <section className="wrapper wrapper-pre">
        <div>
          <h1>Api stats fetched at</h1>
          <DateField source='matchEndedAt' showTime={true} />
        </div>
        <div>
          <h1>Ready</h1>
          <BooleanField source="ready" />
        </div>
        <div>
          <h1>Wallet</h1>
          <Hash hash={record.wallet} mode="address" clickable={true} shorten={"10"} />
        </div>
        <div>
          <h1>Balance</h1>
          <TextField source="balance" /> EVOLVE
        </div>
      </section>
      <section className="wrapper wrapper-pre">
        <div>
          <h1>Status</h1>
          <TextField source="status" />
        </div>
        <div>
          <h1>Side</h1>
          <TextField source='side' />
        </div>
      </section>
      <section className="wrapper wrapper-pre">
      <h1>Public user info:</h1>
        <pre>
          <ReactJson src={record.publicUserInfo} displayDataTypes={false} quotesOnKeys={false} />
        </pre>
        <h1>Conclusion:</h1>
        <pre>
          <ReactJson src={record.conclusion} displayDataTypes={false} quotesOnKeys={false} />
        </pre>
      </section>
      <section className="wrapper wrapper-pre">
        <h1>API stats:</h1>
        <pre>
          <ReactJson src={record.apiStats} displayDataTypes={false} quotesOnKeys={false} />
        </pre>
        <h1>API resp after:</h1>
        <pre>
          <ReactJson src={record.apiRespAfter} displayDataTypes={false} quotesOnKeys={false} />
        </pre>
      </section>
    </>
  )
}

export { CompetitorStatusDetail };
