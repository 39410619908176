import React from "react";
import Parse from "parse";

import {
  List,
  Datagrid,
  DateField,
  ShowButton,
  FunctionField,
  TextField,
  SelectInput,
  Link,
} from "react-admin";
import { PointerField, CustomTable } from "../../components";
import { Hash } from "../../components/Hash";
import { FilterDate } from "../../components/FilterDate";
import { CustomPagination } from "../../theme/customs/CustomPagination";

const columns = [
  {
    id: "createdAt",
    label: "Created at",
    type: "date",
  },
  {
    id: "status",
    label: "Status",
    type: "string",
  },
  {
    id: "startTxAt",
    label: "Blockchain Logs",
    type: "date",
  },
  {
    id: "blockchainID",
    label: "Blockchain ID",
    type: "number",
  },
  {
    id: "startedAt",
    label: "Started at",
    type: "date",
  },
  {
    id: "finishedAt",
    label: "Finished at",
    type: "date",
  },
  {
    id: "sideScores",
    label: "Winner",
    type: "special0",
  },
  {
    id: "finishTxAt",
    label: "finishTxAt",
    type: "date",
  },
  {
    id: "objectId",
    label: "API Call Logs",
    type: "special1",
  },
  {
    id: "objectId",
    label: "Blockchain Logs",
    type: "special2",
  },

  {
    id: "objectId",
    label: "Show",
    type: "linking",
    linking: "Competition",
  },
];

const CompetitionList = (props) => {
  const [change, setChange] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [selectedDates, setSelectedDates] = React.useState(null);

  const exportCompetitionExcel = async () => {
    const result = await Parse.Cloud.run("export-competitions", {
      datePeriod: selectedDates,
    })
    const url = result._url;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "CompetitionsReport.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <>
      <FilterDate
        setChange={setChange}
        setData={setData}
        resourse="Competition"
        selectedDates={setSelectedDates}
      />
      {change ? (
        <CustomTable
          data={data}
          columns={columns}
          title={""}
          infinite={true}
          perPage={50}
        />
      ) : (
        <List
          pagination={<CustomPagination />}
          bulkActionButtons={false}
          filters={categoryFilters}
          {...props}
          perPage={50}
          sort={{ field: "createdAt", order: "DESC" }}
          exporter={exportCompetitionExcel}
        >
          <Datagrid>
            {
              <FunctionField
                render={(record) => (
                  <Link
                    to={`/${"CompetitionPreset"}/${record["preset"]?.id}/show`}
                  >
                    <PointerField
                      record={record}
                      // isLinked={true}
                      reference="CompetitionPreset"
                      subClass="preset"
                      subValue="feeInUSD"
                      label="Preset"
                      twoTimes={true}
                      subClass2="game"
                      subValue2="title"
                    />{" "}
                    <span>
                      <PointerField
                        record={record}
                        reference="CompetitionPreset"
                        subClass="preset"
                        subValue="players"
                      />{" "}
                      <span>vs</span>{" "}
                      <PointerField
                        record={record}
                        reference="CompetitionPreset"
                        subClass="preset"
                        subValue="players"
                      />
                    </span>{" "}
                    <span>$</span>
                    <PointerField
                      record={record}
                      reference="CompetitionPreset"
                      subClass="preset"
                      subValue="feeInUSD"
                    />{" "}
                    <span>#</span>
                    <PointerField
                      record={record}
                      reference="CompetitionPreset"
                      subClass="preset"
                      subValue="blockchainID"
                    />
                  </Link>
                )}
              />
            }

            <DateField showTime={true} source="createdAt" label="Created at" />
            <TextField source="status" label="Status" />
            <FunctionField
              label={"Start TX. At"}
              render={(record) =>
                record.startTxAt ? (
                  record.startTx ? (
                    <Hash
                      hash={record.startTx}
                      clickable={true}
                      mode="date"
                      date={record.startTxAt}
                      shorten={4}
                    />
                  ) : (
                    record.startTxAt.toLocaleString("en-US")
                  )
                ) : (
                  "-"
                )
              }
            />
            <TextField source="blockchainID" label="blockchain ID" />
            <FunctionField
              label={"StartedAt"}
              render={(record) =>
                record.startedAt ? record.startedAt.toLocaleString("en-US") : ""
              }
            />
            <FunctionField
              label={"finishedAt"}
              render={(record) =>
                record.finishedAt
                  ? record.finishedAt.toLocaleString("en-US")
                  : ""
              }
            />
            <FunctionField
              label={"Winner"}
              render={(record) => {
                if (record.sideScores) {
                  if (
                    record.sideScores[0] &&
                    record.sideScores[0].score !== undefined &&
                    record.sideScores[1] &&
                    record.sideScores[1].score !== undefined
                  ) {
                    return record.sideScores[0].score >
                      record.sideScores[1].score
                      ? `Side ${record.sideScores[0].side}`
                      : record.sideScores[0].score < record.sideScores[1].score
                        ? `Side ${record.sideScores[1].side}`
                        : "TIE";
                  }
                }
              }}
            />
            <FunctionField
              label={"Finish TX. At"}
              render={(record) =>
                record.finishTxAt ? (
                  record.finishTx ? (
                    <Hash
                      hash={record.finishTx}
                      clickable={true}
                      mode="date"
                      date={record.finishTxAt}
                      shorten={4}
                    />
                  ) : (
                    record.finishTxAt.toLocaleString("en-US")
                  )
                ) : (
                  "-"
                )
              }
            />
            <FunctionField
              render={(record) => (
                <Link
                  to={`/APICallLog?displayedFilters=%7B%7D&filter=%7B"competition"%3A"${record.id}"%7D&order=ASC&page=1&perPage=50&sort=competition`}
                >
                  API Call Logs
                </Link>
              )}
            />
            <FunctionField
              render={(record) => (
                <Link
                  to={`/BlockchainLog?displayedFilters=%7B%7D&filter=%7B"competition"%3A"${record.id}"%7D&order=DESC&page=1&perPage=25&sort=createdAt`}
                >
                  blockchain Logs
                </Link>
              )}
            />
            <ShowButton />
          </Datagrid>
        </List>
      )}
    </>
  );
};

const categoryFilters = [
  <SelectInput
    alwaysOn
    source="status"
    label="Status"
    choices={[
      { id: "PENDING_READY", name: "PENDING_READY" },
      { id: "PENDING_READY_CANCELED", name: "PENDING_READY_CANCELED" },
      { id: "PENDING_READY_TIMEDOUT", name: "PENDING_READY_TIMEDOUT" },
      { id: "PENDING_START_TX", name: "PENDING_START_TX" },
      { id: "X_START_TX_FAILED", name: "X_START_TX_FAILED" },
      { id: "X_START_TX_STUCK", name: "X_START_TX_STUCK" },
      { id: "IN_PROGRESS", name: "IN_PROGRESS" },
      { id: "X_FINISH_TX_STUCK", name: "X_FINISH_TX_STUCK" },
      { id: "X_FINISH_TX_FAILED", name: "X_FINISH_TX_FAILED" },
      { id: "COMPLETED", name: "COMPLETED" },
      { id: "COMPLETED_TIMEDOUT", name: "COMPLETED_TIMEDOUT" },
      { id: "SETTLED", name: "SETTLED" },
    ]}
  />,
];
export { CompetitionList };
