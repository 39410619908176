import { CustomTable } from "../../components"
import { useFilterString } from "../../api"

const columns = [{
  id: 'user',
  label: 'User',
  type: 'pointer'
},{
  id: 'amount',
  label: 'Amount',
  type: 'number'
},{
  id: 'from',
  label: 'From',
  type: 'string'
},{
  id: 'to',
  label: 'To',
  type: 'string'
},{
  id: 'createdAt',
  label: 'Created at',
  type: 'date'
}]

const IcoFiat = (props) => {

  const {data} = useFilterString('type','ico-fiat');

  return (
    <CustomTable columns={columns} data={data} />
  )
}

export { IcoFiat }