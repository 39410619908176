import { useEffect, useState } from "react";
import Parse from "parse";
import { useNotify } from "react-admin";

const useFilterString = (field, value) => {
  const [data, setData] = useState([]);
  const notify = useNotify();
  useEffect(() => {
    const query = new Parse.Query("TokenTransaction");
    query.descending("createdAt");
    query.equalTo(field, value);
    query.include("user");
    query
      .find()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        notify(`Error: ${err.message}`);
        console.log("err", err);
      });
    //eslint-disable-next-line
  }, []);

  return { data };
};

export { useFilterString };
