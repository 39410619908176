import {
  Show,
  SimpleShowLayout,
} from "react-admin";
import { CustomTopToolbar } from '../../theme';
import { BlockchainDetail } from "./BlockchainDetail";
import { TitleNavbar } from "../../components";

const BlockchainShow = (props) => {
  return (
    <Show {...props} actions={<CustomTopToolbar />} title={<TitleNavbar mode="Show" field="username" />} >
      <SimpleShowLayout>
        <BlockchainDetail />
      </SimpleShowLayout>
    </Show>
  )
}

export { BlockchainShow }