import { SaveButton, Toolbar } from "react-admin";
const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      <h3 className="hint">Hint: red titles cause blockchain changes</h3>
    </Toolbar>
  );
};

export { CustomToolbar };
