import { SimpleForm, Edit, useNotify, useRefresh } from "react-admin";
import { CompetitionPresetDetail } from "./CompetitionPresetDetail";
import { saveHandler, configEditableFields } from "../../api";
import { TitleNavbar } from "../../components";
import { updatePreset } from "../../contracts";
import { toWei } from "../../utils/metamaskHelper";
import { CustomToolbar } from "../../components";
import { useState } from "react";

const CompetitionPresetEdit = (props) => {
  const [copyState, setCopyState] = useState(null);
  const [settingData, setSettingData] = useState(null);
  const notify = useNotify();
  const refresh = useRefresh();

  function blockchainVerif(data) {
    let vars = ["players", "feeInUSD", "rakeInUsd", "active"];
    vars = vars.map((el) => {
      return copyState[el] == data[el];
    });
    return vars.includes(false);
  }

  async function callSave(data) {
    if (settingData) {
      data.settings = settingData;
    }
    try {
      // edit in backend
      const savedCompetitionPreset = await saveHandler(
        configEditableFields("CompetitionPreset"),
        data,
        props.resource,
        props.id
      );

      if (!savedCompetitionPreset)
        throw new Error("Preset changes didn't save on the backend");

      if (data.feeInUSD !== 0) {
        if (blockchainVerif(data)) {
          console.log("an state is changed");
          // edit in blockchain
          const { transactionHash } = await updatePreset(
            data["blockchainID"],
            toWei(data["feeInUSD"]),
            data["players"],
            new Date(data["createdAt"]).getTime(),
            toWei(data["rakeInUsd"]),
            data["active"]
          );

          // save new storage hash
          await savedCompetitionPreset.save({
            storageTx: transactionHash,
          });
        }
      }

      notify("changes saved ");
      refresh();
    } catch (error) {
      console.log(error);
      notify(`Error : ${error.message}`, { type: "error" });
    }
  }
  return (
    <Edit {...props} title={<TitleNavbar mode="Edit" field="blockchainID" />}>
      <SimpleForm save={(data) => callSave(data)} toolbar={<CustomToolbar />}>
        <CompetitionPresetDetail
          setSettingData={setSettingData}
          mode="edit"
          setCopyState={setCopyState}
        />
      </SimpleForm>
    </Edit>
  );
};

export { CompetitionPresetEdit };
