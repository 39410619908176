import React from "react";

import { List, Datagrid, TextField, DateField } from "react-admin";
import { PointerField } from "../../components";
import { CustomPagination } from "../../theme/customs/CustomPagination";

const TokenTransactionList = (props) => {
  return (
    <List
      perPage={50}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid>
        <PointerField
          reference="User"
          subClass="user"
          subValue="username"
          label="User"
          twoTimes={false}
          isLinked={true}
        />
        <TextField source="type" label="Type" />
        <TextField source="amount" label="Amount" />
        <TextField source="from" label="From" />
        <TextField source="to" label="To" />
        <DateField source="createdAt" label="Created at" />
      </Datagrid>
    </List>
  );
};

export { TokenTransactionList };
