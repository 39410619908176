import React from "react";
import { configEditableFields, saveHandler, useFilterPointer } from "../../api";
import { useReadData } from "../../api";
import {
  CustomSaveBtn,
  CustomTable,
  DatePickerTime,
  RelationRow,
} from "../../components";
import { useNotify } from "react-admin";
import { useState } from "react";

const columns = [
  {
    id: "user",
    label: "User",
    type: "pointer",
  },
  {
    id: "createdAt",
    label: "Created at",
    type: "date",
  },
  {
    id: "status",
    label: "status",
    type: "string",
  },
  {
    id: "ready",
    label: "Ready",
    type: "boolean",
  },
  {
    id: "side",
    label: "Side",
    type: "number",
  },
  {
    id: "joinedCompetitionAt",
    label: "Joined Competition At",
    type: "date",
  },
  {
    id: "apiStatsFetchedAt",
    label: "API stats fetched At",
    type: "date",
  },
  {
    id: "apiStats",
    label: "Kills",
    type: "kills",
  },
  {
    id: "wallet",
    label: "Wallet",
    type: "address",
  },
  {
    id: "objectId",
    label: "details",
    type: "competitorStatusPointer",
  },
];

const relationColumns = [
  {
    id: "preset",
    label: "Preset",
    type: "presetName",
  },
  {
    id: "createdAt",
    label: "Created At",
    type: "date",
  },
  {
    id: "status",
    label: "Status",
    type: "string",
  },
  {
    id: "startedAt",
    label: "Started At",
    type: "date",
  },
  {
    id: "startTx",
    label: "Start Tx",
    type: "tx",
  },
  {
    id: "startBlockchainLog",
    label: "Last Start Blockchain Log",
    type: "blockchainLog",
  },
  {
    id: "blockchainID",
    label: "BlockchainID",
    type: "string",
  },
  {
    id: "finishedAt",
    label: "Finished At",
    type: "date",
  },
  {
    id: "finishTx",
    label: "Finish TX.",
    type: "tx",
  },
  {
    id: "finishBlockchainLog",
    label: "Last Finish Blockchain Log",
    type: "blockchainLog",
  },
  {
    id: "sideScores",
    label: "Side scores",
    type: "sideScores",
  },
  {
    id: "trackingStart",
    label: "Tracking Start",
    type: "editable",
    input: "date",
  },
  {
    id: "trackingFinish",
    label: "Tracking Finish",
    type: "editable",
    input: "date",
  },
  {
    id: "error",
    label: "Error",
    type: "string",
  },
];

const CompetitionShow = (props) => {
  const notify = useNotify();
  const { data } = useFilterPointer(
    "CompetitorStatus",
    "competition",
    "pointer",
    { pointerClass: "Competition", pointerId: props.id },
    "side"
  );

  const { data: relationData } = useReadData("Competition", props.id, [
    "preset.game",
  ]);

  const [startDate, setStartDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);
  async function clickHandler() {
    try {
      await saveHandler(
        configEditableFields("Compitition"),
        {
          trackingStart: startDate,
          trackingFinish: finishDate,
        },
        "Competition",
        props.id
      );
      // if (!res) throw new Error("data couldn't be saved on the backend");
      notify("Changes saved");
    } catch (error) {
      console.log(error);
      notify(`Error : ${error.message}`, { type: "error" });
    }
  }
  return (
    <>
      <RelationRow
        data={relationData}
        columns={relationColumns}
        title={"Competition"}
        startDate={startDate}
        setStartDate={setStartDate}
        finishDate={finishDate}
        setFinishDate={setFinishDate}
      />

      <CustomSaveBtn onClick={() => clickHandler()} />
      <CustomTable data={data} columns={columns} title={"Competitors"} />
    </>
  );
};

export { CompetitionShow };
