import React, { useState } from "react";
import { configCreateFields, createHandler } from "../../api";
import {
  Create,
  SimpleForm,
  NumberInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import { MetamaskInteraction } from "../../components/metamask/MetamaskInteraction";
import { useNotify } from "react-admin";
import { addNewPreset } from "../../contracts";
import Parse from "parse";
import { toWei } from "../../utils/metamaskHelper";

const Game = Parse.Object.extend("Game");

const CompetitionPresetCreate = (props) => {
  const notify = useNotify();
  async function saveHandler(params) {
    try {
      const savedCompetitionPreset = await createHandler(
        "CompetitionPreset",
        configCreateFields("CompetitionPreset"),
        params
      );
      console.log("savedCompetitionPreset", savedCompetitionPreset);

      if (params.feeInUSD !== 0) {
        const { transactionHash } = await addNewPreset(
          toWei(params.feeInUSD),
          params.players,
          savedCompetitionPreset.createdAt.getTime(),
          toWei(params.rakeInUsd)
        );

        await savedCompetitionPreset.save({ storageTx: transactionHash });
      }

      notify("Successfully created CompetitionPreset");
    } catch (error) {
      notify(`Error : ${error.message}`, { type: "error" });
      console.log(error);
    }
  }
  return (
    <Create {...props}>
      <MetamaskInteraction>
        <SimpleForm save={saveHandler}>
          <CreateDetail />
        </SimpleForm>
      </MetamaskInteraction>
    </Create>
  );
};

const CreateDetail = (props) => {
  const [gameSettingsTemplates, setGameSettingsTemplates] = React.useState([]);
  const [amount, setAmount] = useState(0);
  const [promotions, setPromotion] = useState([
    { id: "NO_PROMOTION", name: "No promotion" },
    { id: "FREE_ENTRY", name: "Free entry" },
  ]);
  function amountHandler(e) {
    setAmount(e.target.value);
    // eslint-disable-next-line eqeqeq
    if (Number(e.target.value) == 0) {
      setPromotion([{ id: "NO_PROMOTION", name: "No promotion" }]);
    } else {
      setPromotion([
        { id: "NO_PROMOTION", name: "No promotion" },
        { id: "FREE_ENTRY", name: "Free entry" },
      ]);
    }
  }
  return (
    <>
      <section className="wrapper">
        <div>
          <h1>Game:</h1>
          <ReferenceInput
            label=""
            source="game"
            reference="Game"
            onChange={async (e) => {
              const query = new Parse.Query(Game);
              query.equalTo("objectId", e.target.value);
              query.first().then((res) => {
                const settingsTemplates = res.get("settingsTemplates");
                const settingsTemplatesList = settingsTemplates?.map(
                  (setting) => {
                    return {
                      id: setting,
                      name: setting.title,
                    };
                  }
                );
                setGameSettingsTemplates(settingsTemplatesList);
              });
            }}
          >
            <SelectInput optionText="title" defaultValue={""} />
          </ReferenceInput>
        </div>
        <div>
          <h1>Fee in USD:</h1>
          <NumberInput
            source="feeInUSD"
            label=""
            defaultValue={1}
            onChange={amountHandler}
            value={amount}
          />
        </div>
        <div>
          <h1>Players per side:</h1>
          <NumberInput source="players" label="" defaultValue={0} />
        </div>
        <div>
          <h1>Rake Amount in USD :</h1>
          <NumberInput source="rakeInUsd" label="" defaultValue={0} />
        </div>
      </section>
      <section className="wrapper">
        {/* <div>
          <h1>Supported Tokens : example: 1 or 1,2,3</h1>
          <TextInput placeholder="1,2,3" source="_supportedTokens" label="" />
        </div> */}
        <div>
          <h1>Promotion:</h1>
          <SelectInput
            source="promotion"
            label=""
            choices={promotions}
            defaultValue={"NO_PROMOTION"}
          />
        </div>
        <div>
          <h1>settings:</h1>
          <SelectInput
            source="settings"
            label=""
            choices={gameSettingsTemplates}
            defaultValue={""}
          />
        </div>
        <div>
          <h1>Level required:</h1>
          <NumberInput source="levelRequired" label="" defaultValue={1} />
        </div>
      </section>{" "}
      <section className="wrapper">
        <div>
          <h1>Currency:</h1>
          <SelectInput
            source="currency"
            label=""
            choices={[{ id: "STABLE_COINS", name: "STABLE_COINS" }]}
            defaultValue={"NO_PROMOTION"}
          />
        </div>
      </section>
      <section className="wrapper">
        <div>
          <h1>Description:</h1>
          <TextInput
            multiline
            minRows={3}
            source="description"
            label=""
            initialValue=""
          />
        </div>
        <div>
          <h1>Active:</h1>
          <BooleanInput label="" source="active" defaultValue={false} />
        </div>
        <div>
          <h1>KYC required:</h1>
          <BooleanInput label="" source="KYCRequired" defaultValue={false} />
        </div>
        <div>
          <h1>Coming soon:</h1>
          <BooleanInput label="" source="comingSoon" defaultValue={false} />
        </div>
      </section>
    </>
  );
};

export { CompetitionPresetCreate };
