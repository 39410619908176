import React, { useEffect, useState } from "react";
import {
  TextField,
  FunctionField,
  DateField,
  BooleanInput,
  SelectInput,
  TextInput,
  NumberField,
  NumberInput,
} from "react-admin";
import ReactJson from "react-json-view";
import { PointerField } from "../../components";
import { Hash } from "../../components/Hash";

//// test
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const CompetitionPresetDetail = (props) => {
  const { record, mode, setCopyState, setSettingData } = props;
  const [settingChoices, setSettingChoices] = useState([]);
  const [defaultValue, setDefaultValue] = useState();

  useEffect(() => {
    setCopyState(record);
    record.game.fetch().then((res) => {
      const choices = res.get("settingsTemplates")?.map((setting, index) => ({
        id: `${index}`,
        name: setting.title,
        info: setting,
      }));
      setSettingChoices(choices);
      setDefaultValue("1");
    });
  }, [record]);

  function isFeeZero(fee = record.feeInUSD) {
    return fee === 0 ? true : false;
  }

  function isBCZero(bc = record.blockchainID) {
    if (bc) return false;
    else return true;
  }

  return (
    <>
      <section className="wrapper">
        <div>
          <h1>Game:</h1>
          <PointerField
            reference="Game"
            subClass="game"
            subValue="title"
            label="Game"
            isLinked={false}
            record={record}
          />
        </div>
        <div>
          <h1 className={isFeeZero() ? null : "text-red"}>Players:</h1>
          <NumberInput
            source="players"
            label={`${record.players} vs ${record.players}`}
          />
        </div>
        <div>
          <h1 className={isFeeZero() ? null : "text-red"}>Fee:</h1>
          {isBCZero() ? (
            <TextField source="rakeInUsd" />
          ) : (
            <NumberInput source="feeInUSD" label="" />
          )}
        </div>
        <div>
          <h1>BlockchainID:</h1>
          <TextField source="blockchainID" />
        </div>
        <div>
          <h1>Created at:</h1>
          <DateField source="createdAt" showTime={true} />
        </div>
      </section>
      {/* From here */}
      <section className="wrapper">
        <div>
          <h1>Currency:</h1>
          <SelectInput
            source="currency"
            label=""
            choices={[
              // { id: "EVOLVE", name: "EVOLVE" },
              { id: "STABLE_COINS", name: "STABLE_COINS" },
            ]}
            defaultValue={""}
          />
        </div>
        <div>
          <h1 className={isFeeZero() ? null : "text-red"}>Rake in USD:</h1>
          {isBCZero() ? (
            <TextField source="rakeInUsd" />
          ) : (
            <NumberInput source="rakeInUsd" label="" />
          )}
        </div>
        <div>
          <h1>Bot Mode:</h1>
          <SelectInput
            source="botMode"
            label=""
            choices={[
              { id: "LOSER_BOTS", name: "LOSER_BOTS" },
              { id: "OFF", name: "OFF" },
            ]}
            defaultValue={""}
          />
        </div>
        <div>
          {/* <h1>BlockchainID:</h1>
          <TextField source="blockchainID" /> */}
        </div>
        <div>
          {/* <h1>Created at:</h1>
          <DateField source="createdAt" showTime={true} /> */}
        </div>
      </section>
      <section className="wrapper">
        <div>
          <h1 className={isFeeZero() ? null : "text-red"}>Active:</h1>
          {mode === "edit" ? (
            <BooleanInput source="active" label="" />
          ) : (
            <FunctionField
              label="Active"
              render={(record) => (record.active ? "Yes" : "No")}
            />
          )}
        </div>
        <div>
          <h1>KYC Required:</h1>
          {mode === "edit" ? (
            <BooleanInput source="KYCRequired" label="" />
          ) : (
            <FunctionField
              label="KYC Required"
              render={(record) => (record.KYCRequired ? "Yes" : "No")}
            />
          )}
        </div>
        <div>
          <h1>Coming Soon:</h1>
          {mode === "edit" ? (
            <BooleanInput source="comingSoon" label="" />
          ) : (
            <FunctionField
              label="Coming Soon"
              render={(record) => (record.comingSoon ? "Yes" : "No")}
            />
          )}
        </div>
        <div>
          <h1>Level Required:</h1>
          {mode === "edit" ? (
            <NumberInput source="levelRequired" label="" />
          ) : (
            <NumberField source="levelRequired" label="" />
          )}
        </div>
        <div>
          <h1>Promotion:</h1>
          {mode === "edit" ? (
            <SelectInput
              source="promotion"
              label=""
              choices={
                Number(record.feeInUSD)
                  ? [
                      { id: "NO_PROMOTION", name: "No promotion" },
                      { id: "FREE_ENTRY", name: "Free entry" },
                    ]
                  : [{ id: "NO_PROMOTION", name: "No promotion" }]
              }
              defaultValue={""}
            />
          ) : (
            <TextField source="promotion" />
          )}
        </div>
      </section>
      <section className="wrapper wrapper-pre">
        <div>
          <h1>StorageTx:</h1>
          <FunctionField
            label="storageTx"
            render={(record) =>
              record.storageTx ? (
                <Hash
                  hash={record.storageTx}
                  clickable={true}
                  mode="tx"
                  date={record.storageTxAt}
                  shorten={4}
                />
              ) : (
                "-"
              )
            }
          />
        </div>
        <div>
          <h1>Description:</h1>
          {mode === "edit" ? (
            <TextInput
              multiline
              source="description"
              label=""
              initialValue="Description"
            />
          ) : (
            <TextField source="description" />
          )}
        </div>
        <div>
          <h1>Settings title:</h1>
          {/* <SelectInput
            source="settings"
            label={""}
            choices={settingChoices}
            optionValue="id"
            optionText="name"
            defaultValue={defaultValue}
          /> */}
          <FunctionField
            render={(record) => (
              <SettingTitle
                record={record}
                choices={settingChoices}
                setSettingData={setSettingData}
              />
            )}
          />
        </div>
        <div>
          <h1>Settings:</h1>
          <pre>
            <ReactJson
              src={record.settings}
              displayDataTypes={false}
              quotesOnKeys={false}
            />
          </pre>
        </div>
      </section>
    </>
  );
};

const SettingTitle = ({ record, choices, setSettingData }) => {
  const [defaultValue, setDefaultValue] = React.useState("");

  const handleChange = (e) => {
    console.log("e", typeof e.target.value);
    setDefaultValue(e.target.value);
    setSettingData(choices.find((el) => el.id === e.target.value).info);
  };
  useEffect(() => {
    if (choices[0]) {
      const res =
        choices.find((el) => el.name === record.settings.title)?.id || "";
      setDefaultValue(res);
    }
  }, [choices]);

  return (
    <FormControl variant="filled">
      <InputLabel id="demo-simple-select-filled-label"></InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={defaultValue}
        onChange={handleChange}
      >
        {choices.map((el) => (
          <MenuItem value={el.id}>{el.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { CompetitionPresetDetail };
