import {
  FunctionField,
  // BooleanField, BooleanInput, DateField, DateInput, EmailField, FunctionField, ImageField,
  TextField,
  Link,
  // TextInput
} from "react-admin";
import ReactJson from "react-json-view";
// import { QueryLinking } from '../../components';
import { Hash } from "../../components/Hash";

const BlockchainDetail = (props) => {
  // const { record } = props;
  return (
    <>
      <section className="wrapper">
        <div>
          <h1>Competition:</h1>
          <FunctionField
            label={"Competition"}
            render={(record) => (
              <Link
                to={`/${"Competition"}/${record["competition"]?.id}/show`}
              >
                {record["competition"]?.id}
              </Link>
            )}
          />
        </div>
        <div>
          <h1>Transaction hash:</h1>
          <FunctionField render={
            (record) => record.transactionHash ? (
              <Hash hash={record.transactionHash} mode="tx" clickable={true} shorten={"5"} />
            ) : <p>_</p>}
          />
        </div>
      </section>
      <section className="wrapper">
        <div>
          <h1>Called function:</h1>
          <TextField source="calledFunction" label="Called function" />
        </div>
        <div>
          <h1>Status:</h1>
          <FunctionField render={
            (record) => record.status ? (
              record.status
            ) : <p>_</p>}
          />
        </div>
        <div>
          <h1>Competition ID:</h1>
          <FunctionField render={
            (record) => record.competitionId ? (
              record.competitionId
            ) : <p>_</p>}
          />
        </div>
        <div>
          <h1>Preset ID:</h1>
          <FunctionField render={
            (record) => record.presetId ? (
              record.presetId
            ) : <p>_</p>}
          />
        </div>
      </section>
      <section className="wrapper wrapper-pre">
        <h1>Details:</h1>
        <FunctionField render={
          (record) => record.details ? (
            <pre>
              <ReactJson src={record.details} displayDataTypes={false} quotesOnKeys={false} />

            </pre>
          ) : <p>No details</p>}
        />
      </section>
      <section className="wrapper wrapper-pre">
        <h1>Error:</h1>
        <FunctionField source='error' label="details" render={
          (record) => record.error ? (
            <TextField source='error' label="details" />
          ) : <p>No error</p>}
        />
      </section>
    </>
  )
}

export { BlockchainDetail };
